import { Wallet } from "@dynamic-labs/sdk-react-core";
import { avalanche, avalancheFuji } from "viem/chains";
import { isProduction } from "./environment";
import { USDC_ABI } from "@/constants/usdcABI";
import { usdToUsdc } from "./convert";

interface GenPermitSigArgs {
  primaryWallet: Wallet | null;
  tokenAddress: string;
  walletAddress: string;
  spenderAddress: string;
  amount: number;
  deadline?: number;
}

export const generatePermitSignature = async ({
  primaryWallet,
  tokenAddress,
  walletAddress,
  spenderAddress,
  amount,
  deadline = Math.floor(Date.now() / 1000) + 3600, // 1 hour from now
}: GenPermitSigArgs): Promise<string> => {
  if (
    !primaryWallet ||
    !tokenAddress ||
    !walletAddress ||
    !spenderAddress ||
    !amount ||
    amount <= 0
  ) {
    console.log({
      mstatus: "Incorrect generatePermitSignature params",
      primaryWallet,
      tokenAddress,
      walletAddress,
      spenderAddress,
      amount,
    });
    return "";
  }

  const publicClient = await primaryWallet?.getPublicClient();
  const nonce = await publicClient.readContract({
    address: tokenAddress,
    abi: USDC_ABI,
    functionName: "nonces",
    args: [walletAddress],
  });

  const domain = {
    name: "USD Coin",
    version: "2",
    chainId: isProduction ? avalanche.id : avalancheFuji.id,
    salt: "0x" + "0".repeat(64),
    verifyingContract: tokenAddress,
  };

  const types = {
    Permit: [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ],
  };

  const message = {
    owner: walletAddress,
    spender: spenderAddress,
    value: usdToUsdc(amount),
    nonce,
    deadline,
  };
  const typedData = { primaryType: "Permit", domain, types, message };

  try {
    const walletClient = await primaryWallet.getWalletClient();
    const signature = await walletClient.signTypedData(typedData);
    console.log({
      mstatus: "Permit signature generated",
      signature,
      typedData,
    });
    return signature;
  } catch (error) {
    console.log({
      mstatus: "Error generating permit signature",
      error,
      typedData,
    });
    console.error(error);
    return "";
  }
};
