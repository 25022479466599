import { useState, useEffect } from "react";

const breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

type Breakpoint = keyof typeof breakpoints;

interface BreakpointReturn {
  bp: Breakpoint;
  isGreaterThan: (breakpoint: Breakpoint) => boolean;
}

const useBreakpoints = (): BreakpointReturn => {
  const [width, setWidth] = useState(0);
  const [breakpoint, setBreakpoint] = useState<Breakpoint>("xs");

  useEffect(() => {
    const getBreakpoint = (width: number): Breakpoint => {
      const breakpointEntries = Object.entries(breakpoints) as [
        Breakpoint,
        number,
      ][];
      const found = breakpointEntries
        .reverse()
        .find(([_, minWidth]) => width >= minWidth);

      return found?.[0] ?? "xs";
    };

    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setWidth(currentWidth);
      setBreakpoint(getBreakpoint(currentWidth));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isGreaterThan: BreakpointReturn["isGreaterThan"] = (breakpoint) =>
    width >= breakpoints[breakpoint];

  return { bp: breakpoint, isGreaterThan };
};

export default useBreakpoints;
