import React from "react";
import Logo from "@/assets/svg/vs-button/Vector.svg?react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface Props {
  percentage?: number;
}

const PercentageBar: React.FC<Props> = ({ percentage = 50 }) => {
  // Ensure percentage is between 0 and 100
  const clampedPercentage = Math.min(100, Math.max(0, percentage));

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div
            className="relative h-[0.625rem] w-24 px-1"
            style={{ backgroundColor: "hsla(142, 71%, 45%, 1)" }}>
            <div
              className="absolute left-0 top-0 h-full"
              style={{
                width: `${clampedPercentage}%`,
                backgroundColor: "hsla(350, 89%, 60%, 1)",
              }}
            />
            <div
              className="absolute inset-0 -top-[4px] h-full w-5"
              style={{ left: `calc(${clampedPercentage}% - 10px)` }}>
              <Logo height={15} />
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent className="border border-slate-400 bg-secondary">
          <div className="flex space-x-4 text-sm">
            <div className="flex flex-col">
              <span>Drake</span>
              <div>
                <span>44%/ $ 80</span>
                <span className="text-muted-foreground">Pool Share</span>
              </div>
            </div>
            <div className="font-geist flex flex-col border-l-2 border-slate-100 pl-4">
              <div className="flex flex-col">
                <span className="">Kendrick Lamarke</span>
                <div className="flex gap-1">
                  <span>56%/ $ 100</span>
                  <span className="text-muted-foreground">Pool Share</span>
                </div>
              </div>
            </div>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default React.memo(PercentageBar);
