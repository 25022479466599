import React from "react";
import ReactDOM from "react-dom/client";

import "@/polyfills";

import "@/main.css";
import { ThemeProvider } from "@/contexts/Theme";

import { SidebarProvider } from "./contexts/SidebarContext";
import { ReactQueryProvider } from "@/contexts/ReactQuery";
import "@/translations/i18n";
import store from "./store";
import { Provider } from "react-redux";
import ConrtainerComponent from "./container";
import { WalletProvider } from "./contexts/WalletContext";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <WalletProvider>
        <SidebarProvider>
          <ReactQueryProvider>
            <ThemeProvider>
              <ConrtainerComponent />
            </ThemeProvider>
          </ReactQueryProvider>
        </SidebarProvider>
      </WalletProvider>
    </Provider>
  </React.StrictMode>
);
