import { Wallet } from "@dynamic-labs/sdk-react-core";
import { BiconomySmartAccountV2 } from "@biconomy/account";
import { WalletClient } from "viem";

import { WalletType } from "@/types";
import store, { walletActions } from "@/store";
import { createSmartAccount } from "./biconomy";

type WalletInstance = Wallet | BiconomySmartAccountV2 | null;

type GetWalletInstanceReturn = {
  walletType: WalletType;
  walletAddress: string | null;
  wallet: WalletInstance;
};

export const getWalletInstance = async (
  primaryWallet: Wallet | null,
  setWallet: (wallet: WalletInstance) => void
): Promise<GetWalletInstanceReturn> => {
  const walletClient: WalletClient = await primaryWallet?.getWalletClient();
  const isEmbedded = primaryWallet?.connector.isEmbeddedWallet;
  let walletType = WalletType.NONE;
  let walletAddress = primaryWallet?.address ?? null;
  let wallet: WalletInstance = primaryWallet;

  if (isEmbedded !== undefined) {
    walletType = isEmbedded ? WalletType.SMART_ACCOUNT : WalletType.EOA;
  }

  if (isEmbedded) {
    wallet = await createSmartAccount(walletClient);
    walletAddress = await wallet.getAccountAddress();
  }

  setWallet(wallet);

  store.dispatch(walletActions.setWalletType(walletType));
  store.dispatch(walletActions.setWalletAddress(walletAddress));

  return { walletType, walletAddress, wallet };
};
