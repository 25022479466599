import { DateDiff } from "@/types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);

export const formatDate = (
  timestamp: number,
  format: "short" | "long" = "long"
) =>
  format === "long"
    ? dayjs(timestamp).format("DD MMM YYYY") // "05 Dec 2024"
    : dayjs(timestamp).format("DD MMM"); // "05 Dec"

/**
 * @description Finds difference between two dates
 * @param startDate UTC data-time string
 * @param endDate UTC data-time string
 * @returns { days: number, minutes: number, hours: number }
 * @example getDiff('2025-12-01T00:00:00.000Z', '2025-12-01T00:00:00.000Z')
 */
export const getDiff = (startDate: string, endDate: string): DateDiff => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const hoursDiff = start.diff(end, "h");
  const minutesDiff = start.diff(end, "m");
  const days = Math.floor(hoursDiff / 24);
  const hours = hoursDiff - days * 24;
  const minutes = minutesDiff - Math.floor(hoursDiff * 60);

  return {
    days,
    hours,
    minutes,
  };
};

export const getDiffFrom = (date: string) => {
  const from = dayjs(date);
  return from.format("DD/MM/YYYY, HH:mm:ss");
};

/**
 * @description Converts a date to its corresponding week
 * number in the calendar year.
 * @param date - UTC data-time string
 * @returns The week number (1-53) in the calendar year
 * @example dateToWeekOfYear('2025-12-01T00:00:00.000Z')
 */
export const dateToWeekOfYear = (date: string) => dayjs(date).week()
