import { UserProfile, Wallet } from "@dynamic-labs/sdk-react-core";

import { client } from "@/api/base";
import { LoginResponse } from "@/types";
import store, { authActions } from "@/store";
import { getWalletInstance } from "@/helpers";
import { BiconomySmartAccountV2 } from "@biconomy/account";

export const getHasJWT = (): boolean => !!localStorage.getItem("access_token");
export const getJWT = (): string | null => localStorage.getItem("access_token");
export const setJWT = (authToken: string): void =>
  localStorage.setItem("access_token", authToken);

export const getHasRefreshToken = (): boolean =>
  !!localStorage.getItem("refresh_token");
export const getRefreshToken = (): string | null =>
  localStorage.getItem("refresh_token");
export const setRefreshToken = (refreshToken: string): void =>
  localStorage.setItem("refresh_token", refreshToken);
export const deleteAuthTokens = (): void => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
};

interface AuthSuccessArgs {
  handleLogOut: () => Promise<void>;
  isAuthenticated: boolean;
  primaryWallet: Wallet | null;
  user: UserProfile;
}

export const handleAuthSuccess = async (
  { user, primaryWallet }: AuthSuccessArgs,
  setWallet: (wallet: Wallet | BiconomySmartAccountV2 | null) => void
) => {
  try {
    const isEmailUsed = await client.clearParams().post("is-email-used", {
      email: user.email,
    });
    const { walletAddress } = await getWalletInstance(primaryWallet, setWallet);
    const signedInUser = !isEmailUsed
      ? await client.post<LoginResponse>("users/create-new-user", {
          email: user.email,
          login_type: 2,
          username: user.username,
          wallet: walletAddress,
          auth_identifier: user.userId,
          // refcode: "123123",
        })
      : await client.post<LoginResponse>("auth/login", {
          email: user.email,
          auth_identifier: user.userId,
        });

    store.dispatch(authActions.setAccessToken(signedInUser.accessToken));
    store.dispatch(authActions.setRefreshToken(signedInUser.refreshToken));

    setJWT(signedInUser.accessToken);
    setRefreshToken(signedInUser.refreshToken);
    client.setHeaders({ Authorization: `Bearer ${signedInUser.accessToken}` });
  } catch (error) {
    // TODO: trigger alert to show the user that something went wrong
    // handleLogOut();
    console.error(error);
  }
};

// todo: on signout, lose/clear token balance
export const handleAuthLogout = (): void => {
  store.dispatch(authActions.clearTokens());
  deleteAuthTokens();
  client.clearHeaders();
};
