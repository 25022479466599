export enum ContractFunc {
  GET_USDC_BALANCE = "balanceOf",
  APPROVE_USDC = "approve",
  CREATE_BATTLE = "createBattle",
  CREATE_BATTLE_WITH_TICKETS = "createBattleWithTickets",
  MAKE_PREDICTION = "makePrediction",
  MAKE_PREDICTION_WITH_TICKETS = "makePredictionWithTickets",
  ADD_SIZE = "updateAmount",
  ADD_SIZE_TICKETS = "updateAmountWithTickets",
  CLAIM_MARKETMAKER_INCENTIVES = "claimMarketMakerIncentive",
  CLAIM_WIN = "claimWin",
  CLAIM_REFUND = "claimRefund",
}

export interface ApproveUsdcArgs {
  amount: number;
}

export interface CreateBattleArgs {
  manifest: {
    battleType: 0 | 1;
    option0Id: string; // spotify id
    option1Id: string; // spotify id
  };
  option: 1 | 2;
  amount: number;
  useNextWindow?: boolean;
  useRelayer?: boolean;
}

export interface CreateBattleWithTicketsArgs {
  manifest: {
    battleType: 0 | 1;
    option0Id: string; // spotify id
    option1Id: string; // spotify id
  };
  option: 1 | 2;
  ticketsIds: number[];
  useNextWindow?: boolean;
  useRelayer?: boolean;
}

export interface MakePredictionArgs {
  battleId: number;
  option: 1 | 2;
  amount: number;
  permitSig?: string;
  useRelayer?: boolean;
}
export interface MakePredictionWithTicketsArgs {
  battleId: number;
  ticketsIds: number[];
  option: 1 | 2;
  useRelayer?: boolean;
}

export interface AddSizeArgs {
  battleId: number;
  amount: number;
  permitSig?: string;
  useRelayer?: boolean;
}

export interface AddSizeWithTicketsArgs {
  battleId: number;
  ticketsIds: number[];
  useRelayer?: boolean;
}

export interface ClaimMarketMakerIncentivesArgs {
  battleIds: number[];
}

export interface ClaimWinArgs {
  battleIds: number[];
  useRelayer?: boolean;
}

export interface ClaimRefundArgs {
  battleIds: number[];
  useRelayer?: boolean;
}

export type ContractTxDataMap = {
  [ContractFunc.GET_USDC_BALANCE]: Record<string, never>;
  [ContractFunc.APPROVE_USDC]: ApproveUsdcArgs;
  [ContractFunc.CREATE_BATTLE]: CreateBattleArgs;
  [ContractFunc.CREATE_BATTLE_WITH_TICKETS]: CreateBattleWithTicketsArgs;
  [ContractFunc.MAKE_PREDICTION]: MakePredictionArgs;
  [ContractFunc.MAKE_PREDICTION_WITH_TICKETS]: MakePredictionWithTicketsArgs;
  [ContractFunc.ADD_SIZE]: AddSizeArgs;
  [ContractFunc.ADD_SIZE_TICKETS]: AddSizeWithTicketsArgs;
  [ContractFunc.CLAIM_MARKETMAKER_INCENTIVES]: ClaimMarketMakerIncentivesArgs;
  [ContractFunc.CLAIM_WIN]: ClaimWinArgs;
  [ContractFunc.CLAIM_REFUND]: ClaimRefundArgs;
};
