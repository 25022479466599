import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { cn } from "@/helpers/tailwind";

import Sidebar from "@/components/layout/Sidebar";
import BottomMenu from "@/components/layout/MobileMenu";
import { useSidebar } from "@/contexts/SidebarContext";
import { useWalletInitialization } from "@/hooks";

const PATHS_WITH_OWN_SCROLLING = ["/battles"];

const Root: React.FC = () => {
  const { isExpanded } = useSidebar();
  const location = useLocation();
  useWalletInitialization();

  const avoidBottomNavSpacing = () =>
    PATHS_WITH_OWN_SCROLLING.includes(location.pathname);

  return (
    <div className="md:px-[1%]">
      <div className="flex h-dvh md:gap-[1%]">
        <aside
          className={cn(
            "hidden h-full py-4 transition-all duration-300 ease-in-out md:block",
            {
              "w-[16.666%]": isExpanded,
              "w-[8.333%]": !isExpanded,
            }
          )}>
          <Sidebar />
        </aside>
        <BottomMenu />
        <main
          className={cn(
            "scrollbar max-h-dvh w-full overflow-y-auto transition-all duration-300 ease-in-out md:pt-4",
            {
              "md:w-[83.333%]": isExpanded,
              "md:w-[91.666%]": !isExpanded,
              "bottom-nav-spacing": !avoidBottomNavSpacing(),
            }
          )}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default React.memo(Root);
