import React, { Suspense, useEffect } from "react";
import { createBrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createConfig, WagmiProvider } from "wagmi";
import { http } from "viem";
import { avalancheFuji } from "viem/chains";

import { routes } from "@/router/routes";
import SuspenseFallback from "@/components/layout/SuspenseFallback";
import {
  getJWT,
  getRefreshToken,
  handleAuthLogout,
  handleAuthSuccess,
} from "./helpers";
import { useWallet } from "./contexts/WalletContext";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { authActions } from "./store";
import { client } from "./api/base";

const router = createBrowserRouter(routes);
const evmNetworks = [
  {
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    chainId: 43113,
    chainName: "Avalanche Fuji",
    iconUrls: ["https://app.dynamic.xyz/assets/networks/avax.svg"],
    name: "Avalanche Fuji",
    nativeCurrency: {
      decimals: 18,
      name: "Avax",
      symbol: "AVAX",
    },
    networkId: 43113,
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    vanityName: "Avalanche Fuji",
  },
];

const config = createConfig({
  chains: [avalancheFuji],
  multiInjectedProviderDiscovery: false,
  transports: {
    [avalancheFuji.id]: http(),
  },
});

const ContainerComponent: React.FC = () => {
  const { setWallet } = useWallet();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  // Set required data on first mount of the app
  useEffect(() => {
    const accesToken = getJWT();
    const refreshToken = getRefreshToken();
    if (accesToken && refreshToken) {
      client.setHeaders({ Authorization: `Bearer ${accesToken}` });
      dispatch(authActions.setAccessToken(accesToken));
      dispatch(authActions.setRefreshToken(refreshToken));
    }
  }, []);

  return (
    <DynamicContextProvider
      settings={{
        environmentId: "d7677a9c-96be-4034-bc77-6ed585651435", // todo: move to .env
        overrides: { evmNetworks },
        walletConnectors: [EthereumWalletConnectors],
        events: {
          onAuthSuccess: async (data) => {
            await handleAuthSuccess(data, setWallet);
            queryClient.invalidateQueries({ refetchType: "all" });
          },
          onLogout: () => {
            handleAuthLogout();
            queryClient.invalidateQueries({ refetchType: "all" });
          },
        },
      }}>
      <WagmiProvider config={config}>
        <Suspense fallback={<SuspenseFallback />}>
          <DynamicWagmiConnector>
            <RouterProvider router={router} />
          </DynamicWagmiConnector>
        </Suspense>
      </WagmiProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </DynamicContextProvider>
  );
};

export default React.memo(ContainerComponent);
