import React from "react";
import { useSelector } from "react-redux";
import { BiconomySmartAccountV2, PaymasterMode } from "@biconomy/account";
import { encodeFunctionData, formatUnits } from "viem";
import { useDynamicContext, Wallet } from "@dynamic-labs/sdk-react-core";

import {
  ContractFunc,
  ContractTxDataMap,
  MakePredictionArgs,
  WalletType,
} from "@/types";
import {
  USDC_ABI,
  USDC_DECIMALS,
  WEEKLY_BATTLES_ABI,
  WEEKLY_BATTLES_ADDRESS,
} from "@/constants";
import { getUSDCContractAddress, prepareTxArgs } from "@/helpers";
import { useWallet } from "@/contexts/WalletContext";
import { generatePermitSignature } from "@/helpers/generatePermitSignature";
import { RootState } from "@/store";

type Web3QueryFunction<T> = (args: T) => Promise<void>;

const getAbi = (functionName: ContractFunc) => {
  if (functionName === ContractFunc.APPROVE_USDC) {
    return USDC_ABI;
  }
  return WEEKLY_BATTLES_ABI;
};

const getContractAddress = (functionName: ContractFunc) => {
  if (functionName === ContractFunc.APPROVE_USDC) {
    return getUSDCContractAddress();
  }
  return WEEKLY_BATTLES_ADDRESS;
};

const useWeb3Query = <T extends ContractFunc>(
  functionName: T
): [
  Web3QueryFunction<ContractTxDataMap[T]>,
  boolean,
  string | null,
  unknown | null,
] => {
  const { wallet: walletInstance } = useWallet();
  const walletAddress = useSelector(
    (state: RootState) => state.wallet.walletAddress
  );
  const walletType = useSelector((state: RootState) => state.wallet.walletType);
  const { primaryWallet } = useDynamicContext();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [data, setData] = React.useState<unknown | null>(null);

  const getUSDCBalance = async () => {
    if (!primaryWallet) return;

    try {
      setLoading(true);
      const publicClient = await primaryWallet?.getPublicClient();
      const balance = await publicClient.readContract({
        address: getUSDCContractAddress(),
        abi: USDC_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      const formattedBalance = formatUnits(balance, USDC_DECIMALS);
      setData(formattedBalance);
    } catch (error) {
      console.error("Error fetching USDC balance:", error);
      setError(`Error on balanceOf: ${error}`);
      console.error(`Error on balanceOf:`, error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const submitTransaction = async (
    txData?: ContractTxDataMap[T]
  ): Promise<void> => {
    if (!walletInstance || !walletAddress) {
      console.log({ mstatus: "No wallet instance or wallet address found" });
      return;
    }

    if (functionName === ContractFunc.GET_USDC_BALANCE) {
      return await getUSDCBalance();
    }

    let permitSig = "0x";
    const shouldGeneratePermitSig = [
      ContractFunc.MAKE_PREDICTION,
      ContractFunc.ADD_SIZE,
    ].includes(functionName);

    if (shouldGeneratePermitSig) {
      console.log({ mstatus: `${functionName} / loading permit sig...` });
      setLoading(true);
      try {
        permitSig = await generatePermitSignature({
          primaryWallet,
          tokenAddress: getUSDCContractAddress(),
          walletAddress,
          spenderAddress: getContractAddress(functionName),
          amount: (txData as MakePredictionArgs).amount ?? 0,
        });
      } catch (error) {
        console.error(error);
      }
    }

    const args = prepareTxArgs<T>(functionName, walletAddress, {
      ...txData,
      permitSig,
    } as ContractTxDataMap[T]);
    setLoading(true);

    if (walletType === WalletType.EOA) {
      try {
        console.log({
          mstatus: `${functionName} / EOA / loading...`,
          args,
        });
        const wallet = walletInstance as Wallet;
        const walletClient = await wallet.getWalletClient();
        const publicClient = await wallet.getPublicClient();

        const hash = await walletClient.writeContract({
          address: getContractAddress(functionName),
          account: walletAddress,
          abi: getAbi(functionName),
          functionName,
          args,
        });
        console.log({
          mstatus: `${functionName} / EOA / hash`,
          hash,
          args,
        });
        const receipt = await publicClient.waitForTransactionReceipt({ hash });
        console.log({ mstatus: `${functionName} / EOA/ receipt`, receipt });

        if (receipt.status === "success") {
          setData(receipt);
        } else {
          setError(`Error on ${functionName}: Receipt not success`);
          console.log({
            mstatus: `${functionName} / EOA/ receipt - error`,
            receipt,
          });
        }
      } catch (error) {
        setError(`Error on ${functionName}: ${error}`);
        console.error(`Error on ${functionName}:`, error);
      } finally {
        setLoading(false);
      }
    } else if (walletType === WalletType.SMART_ACCOUNT) {
      try {
        console.log({ mstatus: `${functionName} / SA / loading...`, args });
        const wallet = walletInstance as BiconomySmartAccountV2;
        const userOp = await wallet.sendTransaction(
          {
            to: getContractAddress(functionName),
            data: encodeFunctionData({
              abi: getAbi(functionName),
              functionName: functionName as string,
              args,
            }),
          },
          { paymasterServiceData: { mode: PaymasterMode.SPONSORED } }
        );
        console.log({ userOp });
        const { transactionHash } = await userOp.waitForTxHash();
        console.log({ transactionHash });
        const userOpReceipt = await userOp.wait();
        console.log({
          mstatus: `${functionName} / SA / success`,
          success: userOpReceipt.success,
          receipt: userOpReceipt.receipt,
          userOpReceipt,
        });
        setData(userOpReceipt.receipt);
      } catch (error) {
        setError(`Error on ${functionName}: ${error}`);
        console.error({ mstatus: `${functionName} / SA / error`, error });
      } finally {
        setLoading(false);
      }
    }
  };

  return [submitTransaction, loading, error, data];
};

export default useWeb3Query;
