import { ContractFunc, ContractTxDataMap } from "@/types";
import { WEEKLY_BATTLES_ADDRESS } from "@/constants";
import { usdToUsdc } from "@/helpers";

export const prepareTxArgs = <T extends ContractFunc>(
  functionName: ContractFunc,
  walletAddress: string,
  txData: ContractTxDataMap[T]
): unknown[] => {
  let args: unknown[] = [];

  switch (functionName) {
    case ContractFunc.APPROVE_USDC: {
      const tx = txData as ContractTxDataMap[ContractFunc.APPROVE_USDC];
      args = [WEEKLY_BATTLES_ADDRESS, usdToUsdc(tx.amount)];
      break;
    }
    case ContractFunc.CREATE_BATTLE: {
      const tx = txData as ContractTxDataMap[ContractFunc.CREATE_BATTLE];
      args = [
        walletAddress,
        tx.manifest,
        tx.option,
        usdToUsdc(tx.amount),
        tx.useNextWindow ?? false,
        tx.useRelayer ?? false,
      ];
      break;
    }
    case ContractFunc.CREATE_BATTLE_WITH_TICKETS: {
      const tx =
        txData as ContractTxDataMap[ContractFunc.CREATE_BATTLE_WITH_TICKETS];
      args = [
        walletAddress,
        tx.manifest,
        tx.option,
        tx.ticketsIds,
        tx.useNextWindow ?? false,
        tx.useRelayer ?? false,
      ];
      break;
    }
    case ContractFunc.MAKE_PREDICTION: {
      const tx = txData as ContractTxDataMap[ContractFunc.MAKE_PREDICTION];
      args = [
        walletAddress,
        tx.battleId,
        tx.option,
        usdToUsdc(tx.amount),
        tx.permitSig ?? "0x",
        tx.useRelayer ?? false,
      ];
      break;
    }
    case ContractFunc.MAKE_PREDICTION_WITH_TICKETS: {
      const tx =
        txData as ContractTxDataMap[ContractFunc.MAKE_PREDICTION_WITH_TICKETS];
      args = [
        walletAddress,
        tx.battleId,
        tx.ticketsIds,
        tx.option,
        tx.useRelayer ?? false,
      ];
      break;
    }
    case ContractFunc.ADD_SIZE: {
      const tx = txData as ContractTxDataMap[ContractFunc.ADD_SIZE];
      args = [
        walletAddress,
        tx.battleId,
        usdToUsdc(tx.amount),
        tx.permitSig ?? "0x",
        tx.useRelayer ?? false,
      ];
      break;
    }
    case ContractFunc.ADD_SIZE_TICKETS: {
      const tx = txData as ContractTxDataMap[ContractFunc.ADD_SIZE_TICKETS];
      args = [
        walletAddress,
        tx.battleId,
        tx.ticketsIds,
        tx.useRelayer ?? false,
      ];
      break;
    }
    case ContractFunc.CLAIM_MARKETMAKER_INCENTIVES: {
      const tx =
        txData as ContractTxDataMap[ContractFunc.CLAIM_MARKETMAKER_INCENTIVES];
      args = [tx.battleIds];
      break;
    }
    case ContractFunc.CLAIM_WIN: {
      const tx = txData as ContractTxDataMap[ContractFunc.CLAIM_WIN];
      args = [walletAddress, tx.battleIds, tx.useRelayer ?? false];
      break;
    }
    case ContractFunc.CLAIM_REFUND: {
      const tx = txData as ContractTxDataMap[ContractFunc.CLAIM_REFUND];
      args = [walletAddress, tx.battleIds, tx.useRelayer ?? false];
      break;
    }

    default:
      throw new Error(`Unsupported function name: '${functionName}'`);
  }

  return args;
};
