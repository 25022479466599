import React from "react";
import useTranslate from "@/hooks/useTranslate";
import Skeleton from "react-loading-skeleton";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import "react-loading-skeleton/dist/skeleton.css";

import UsdcIcon from "@/assets/svg/usdc.svg?react";
import { cn } from "@/helpers/tailwind";
import { useTheme } from "@/contexts/Theme";
import { useSidebar } from "@/contexts/SidebarContext";
import { useWeb3Query } from "@/hooks";
import { useWallet } from "@/contexts/WalletContext";
import { ContractFunc } from "@/types/web3Queries";
import store, { walletActions } from "@/store";

const TokenBalance = () => {
  const t = useTranslate();
  const { theme } = useTheme();
  const { isExpanded } = useSidebar();
  const isDark = theme === "dark";
  const isLoggedIn = useIsLoggedIn();
  const { wallet } = useWallet();
  const [getUSDCBalance, _, __, balance] = useWeb3Query(
    ContractFunc.GET_USDC_BALANCE
  );

  React.useEffect(() => {
    const callAsync = async () => {
      try {
        await getUSDCBalance({});
      } catch (error) {
        console.error("Error fetching USDC balance:", error);
      }
    };

    if (isLoggedIn && wallet) {
      callAsync();
      const intervalId = setInterval(callAsync, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, wallet]);

  React.useEffect(() => {
    store.dispatch(walletActions.setBalance(balance as string | null));
  }, [balance]);

  if (!isLoggedIn) {
    return null;
  }

  if (!balance) {
    return <Skeleton width="100" height={32} />;
  }

  return (
    <div
      className={cn("mt-8 flex w-full items-center rounded-lg p-3", {
        "bg-slate-800 text-slate-400": isDark,
        "bg-slate-200 text-slate-600": !isDark,
        "flex-col": !isExpanded,
      })}>
      {isExpanded && (
        <span className="flex-grow text-sm">{t("menu.balance")}</span>
      )}

      <div className={cn({ "mb-2": !isExpanded })}>
        <UsdcIcon
          className={cn("size-4", {
            "text-lightblue": isDark,
            "text-[#94A3B8]": !isDark,
          })}
        />
      </div>
      <span
        className={cn("text-sm font-bold", {
          "ml-2": isExpanded,
          "text-white": isDark,
          "text-gray-900": !isDark,
        })}>
        {balance as string}
      </span>
    </div>
  );
};

export default TokenBalance;
