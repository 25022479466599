import axiosLib, { Axios } from "axios";
import { apiURL } from "@/helpers/environment";
import { ApiClientConfig } from "@/types";
import store, { authActions } from "@/store";

export const axiosInstance = axiosLib.create({
  baseURL: `${apiURL.endsWith("/") ? apiURL : apiURL + "/"}`,
  timeout: 60000,
});

axiosInstance.interceptors.response.use(
  (response) => {
    client.clearParams();
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // To avoid infinite loops.
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await axiosLib.post(`${apiURL}/auth/refresh-token`, {
          refreshToken,
        });
        const { accessToken, refreshToken: newRefreshToken } = response.data;
        // Store the new access and refresh tokens.
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", newRefreshToken);
        store.dispatch(authActions.setAccessToken(accessToken));
        store.dispatch(authActions.setRefreshToken(newRefreshToken));
        // Update the authorization header with the new access token.
        client.setHeaders({ Authorization: `Bearer ${accessToken}` });
        axiosInstance.defaults.headers.common["Authorization"] =
          `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("acces_token");
        localStorage.removeItem("refresh_token");
        store.dispatch(authActions.setAccessToken(null));
        store.dispatch(authActions.setRefreshToken(null));
        // TODO - initiate login flow
        throw refreshError;
      }
    }
    return Promise.reject(error);
  }
);

class ApiClient {
  public readonly axios: Axios;
  private config: ApiClientConfig;

  public constructor(axios: Axios) {
    this.axios = axios;
    this.config = {
      baseURL: `${apiURL.endsWith("/") ? apiURL : apiURL + "/"}`,
      url: "",
      method: "GET",
      params: {},
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  public getConfig() {
    return this.config;
  }

  public setConfig(config: Partial<ApiClientConfig>) {
    this.config = { ...this.config, ...config } as ApiClientConfig;
    return this;
  }

  public setHeaders(headers: Record<string, unknown>) {
    this.config.headers = { ...this.config.headers, ...headers };
    return this;
  }

  public clearHeaders() {
    this.config.headers = { "Content-Type": "application/json" };
    return this;
  }

  public clearParams() {
    this.config.params = {};
    return this;
  }

  private async exec<T>(): Promise<T> {
    const res = await this.axios.request(this.config);
    if (res.status < 200 && res.status >= 300)
      return Promise.reject(`Error with status code: ${res.status}`);

    return res.data;
  }

  public get<T>(url: string = ""): Promise<T> {
    return this.setConfig({
      method: "GET",
      url,
    }).exec();
  }

  public post<T = unknown>(url: string, payload: object = {}): Promise<T> {
    return this.setConfig({
      method: "POST",
      url,
      data: payload,
    }).exec();
  }

  public put<T>() {
    this.config.method = "PUT";
    return this.exec<T>();
  }

  public delete<T>() {
    this.config.method = "DELETE";
    return this.exec<T>();
  }
}

export const client = new ApiClient(axiosInstance);
