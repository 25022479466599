import { INavItem } from "@/types/routing";
import BattleIcon from "@/assets/svg/battle.svg?react";
import { Users, Wallet } from "lucide-react";
import { BATTLES, WALLET, PROFILE } from "@/constants/routes";

export const navItems: Array<INavItem> = [
  {
    namePath: "menu.battles",
    path: BATTLES,
    icon: <BattleIcon />,
  },
  {
    namePath: "menu.wallet",
    path: WALLET,
    authProtected: true,
    icon: <Wallet />,
  },
  {
    namePath: "menu.profile",
    path: PROFILE,
    icon: <Users />,
  },
];
