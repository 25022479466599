import parse from "html-react-parser";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash.isempty";
import i18n from "@/translations/i18n";

type StringObj = { [key: string]: string };
type NodeObj = { [key: string]: ReactNode };
type Variables = StringObj | NodeObj;

/**
 * Replaces parts in given string with variables taken as object (see types).
 *
 * Example:
 * template: "Hello, {planet} is {adjective}. Bye."
 * variables: { adjective: <strong>great</strong>, planet: "Earth" }
 * output: <>Hello, Earth is <strong>great</strong>. Bye.</>
 */
export const interpolateNodes = (
  template: string,
  variables?: NodeObj
): ReactNode => {
  if (isEmpty(template)) return <></>;
  if (isEmpty(variables)) return <>{template}</>;
  const regex = /\{(\w+)\}/g;
  const processedTemp = template.replace(/\{\{/g, "{").replace(/\}\}/g, "}");
  const content = processedTemp
    .split(regex)
    .reduce<ReactNode[]>((nodeList, key, index) => {
      const newNodeList = [...nodeList];
      // only when index is odd there is a placeholder to replace
      if (index % 2 === 1) {
        if (typeof variables === "object") {
          const value = variables[key];
          const parsedValue = typeof value === "string" ? parse(value) : value;
          newNodeList.push(parsedValue ?? `{${key}}`);
        } else {
          newNodeList.push(`{${key}}`);
        }
      } else if (key) {
        newNodeList.push(key);
      }

      return newNodeList;
    }, []);

  return <>{content}</>;
};

const useTranslate = (namespace?: string, i18nInstance?: typeof i18n) => {
  const { t: translate } = useTranslation(namespace ?? "translations", {
    i18n: i18nInstance ?? i18n,
  });

  const tt = (key: string, variables?: Variables): string | ReactNode => {
    if (isEmpty(variables)) return translate(key);

    const variablesArray = Object.keys(variables as StringObj | NodeObj).map(
      (key) => (variables as StringObj | NodeObj)[key]
    );
    const variablesAsNodes: boolean = variablesArray.some(
      (item) => typeof item !== "string"
    );

    if (variablesAsNodes) {
      return interpolateNodes(
        translate(key),
        variables as NodeObj
      ) as ReactNode;
    }

    return translate(key, variables as StringObj);
  };

  // Function overloads to guarantee return type of `t` is based on type of `variables`
  function t(key: string, variables?: StringObj): string;
  function t(key: string, variables?: NodeObj): ReactNode;

  function t(key: string, variables?: StringObj | NodeObj): string | ReactNode {
    return tt(key, variables);
  }

  return t;
};

export default useTranslate;
